import axios from 'src/utils/axios';
import imageToBlob from 'src/utils/imageToBlob';

export const postShippingPlanningApi = async (newShipping) => {
  try {
    const res = await axios.post(`edmu/mes/shipping`, {
      workerId: newShipping?.workerId || undefined,
      contactId: newShipping?.contactId || undefined,
      startDate: newShipping?.startDate || undefined,
      dueDate: newShipping?.dueDate || undefined,
      totalPrice: newShipping?.totalPrice || undefined,
      description: newShipping?.description || undefined,
      itemData: newShipping?.itemData || undefined,
      timeZone: newShipping?.timeZone,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllShippingPlanningApi = async (getShippingPlanningInfor) => {
  try {
    const res = await axios.get('edmu/mes/shipping', {
      params: {
        'start-date[start]': getShippingPlanningInfor.startDateStart || null,
        'start-date[end]': getShippingPlanningInfor.startDateEnd || null,
        'due-date[start]': getShippingPlanningInfor.dueDateStart || null,
        'due-date[end]': getShippingPlanningInfor.dueDateEnd || null,
        'time-zone': getShippingPlanningInfor.timeZone,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const patchShippingPlanningApi = async (modifyShippingInfo) => {
  try {
    const res = await axios.patch(
      `edmu/mes/shipping?id=${modifyShippingInfo.id}`,
      modifyShippingInfo.data,
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteShippingPlanningApi = async (deleteShippingId) => {
  const res = await axios.delete(`edmu/mes/shipping?id=${deleteShippingId}`);
  return res;
};

export const completeShippingPlanningApi = async (completeShipping) => {
  try {
    const res = await axios.post(
      `edmu/mes/shipping/complete?id=${completeShipping.id}`,
      {
        completeDate: completeShipping.completeDate || undefined,
        timeZone: completeShipping.timeZone,
      },
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const cancelShippingPlanningApi = async (completeShipping) => {
  try {
    const res = await axios.post(
      `edmu/mes/shipping/cancel?id=${completeShipping.id}`,
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getShippingPlanningInforApi = async (getShippingInfo) => {
  try {
    const res = await axios.get('edmu/mes/shipping', {
      params: {
        id: getShippingInfo.id || undefined,
        'item-type-id': getShippingInfo.itemTypeId || undefined,
        'unit-id': getShippingInfo.unitId || undefined,
        'country-id': getShippingInfo.countryId || undefined,
        name: getShippingInfo.name || undefined,
        code: getShippingInfo.code || undefined,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getShippingHistoryInforApi = async (getShippingInfo) => {
  try {
    const res = await axios.get('edmu/mes/shipping/history', {
      params: {
        id: getShippingInfo.id || undefined,
        'item-type-id': getShippingInfo.itemTypeId || undefined,
        'unit-id': getShippingInfo.unitId || undefined,
        'country-id': getShippingInfo.countryId || undefined,
        name: getShippingInfo.name || undefined,
        code: getShippingInfo.code || undefined,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteShippingHistoryApi = async (deleteShippingId) => {
  const res = await axios.delete(
    `edmu/mes/shipping/history?id=${deleteShippingId}`,
  );
  return res;
};

export const getAllShippingHistoryApi = async (getShippingHistoryInfor) => {
  try {
    const res = await axios.get('edmu/mes/shipping/history', {
      params: {
        'start-date[start]': getShippingHistoryInfor.startDateStart || null,
        'start-date[end]': getShippingHistoryInfor.startDateEnd || null,
        'due-date[start]': getShippingHistoryInfor.dueDateStart || null,
        'due-date[end]': getShippingHistoryInfor.dueDateEnd || null,
        'complete-date[start]':
          getShippingHistoryInfor.completeDateStart || null,
        'complete-date[end]': getShippingHistoryInfor.completeDateEnd || null,
        'time-zone': getShippingHistoryInfor.timeZone,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const postShippingPlanningItemApi = async (newShipping) => {
  try {
    const res = await axios.post(`edmu/mes/shipping/item`, {
      shippingId: newShipping.shippingId,
      itemId: newShipping.itemId,
      quantity: newShipping.quantity,
      unitPrice: newShipping.unitPrice,
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const patchShippingPlanningItemApi = async (modifyBomItem) => {
  try {
    const res = await axios.patch(
      `edmu/mes/shipping/item?id=${modifyBomItem.id}`,
      {
        itemId: modifyBomItem.itemId,
        quantity: modifyBomItem.quantity,
        unitPrice: modifyBomItem.unitPrice,
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteShippingPlanningItemApi = async (deleteShippingItem) => {
  const res = await axios.delete(
    `edmu/mes/shipping/item?id=${deleteShippingItem}`,
  );
  return res;
};

export const postManufacturingPlanningApi = async (newManufacturing) => {
  try {
    const res = await axios.post(`edmu/mes/manufacturing`, {
      itemId: newManufacturing?.itemId || undefined,
      workerId: newManufacturing?.workerId || undefined,
      bomId: newManufacturing?.bomId || undefined,
      timeZone: newManufacturing?.timeZone,
      dueDate: newManufacturing?.dueDate || undefined,
      quantity: newManufacturing?.quantity || undefined,
      description: newManufacturing?.description || undefined,
      bomItems: newManufacturing?.bomItems || undefined,
      totalPrice: newManufacturing?.totalPrice || undefined,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllManufacturingPlanningApi = async (
  getManufacturingPlanningInfor,
) => {
  try {
    const res = await axios.get('edmu/mes/manufacturing', {
      params: {
        id: getManufacturingPlanningInfor.id || null,
        'item-id': getManufacturingPlanningInfor.itemId || null,
        'due-date[start]': getManufacturingPlanningInfor.dueDateStart || null,
        'due-date[end]': getManufacturingPlanningInfor.dueDateEnd || null,
        'time-zone': getManufacturingPlanningInfor.timeZone,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const patchManufacturingPlanningApi = async (
  modifyManufacturingInfo,
) => {
  try {
    const res = await axios.patch(
      `edmu/mes/manufacturing?id=${modifyManufacturingInfo.id}`,
      modifyManufacturingInfo.data,
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteManufacturingPlanningApi = async (deleteManufacturingId) => {
  const res = await axios.delete(
    `edmu/mes/manufacturing?id=${deleteManufacturingId}`,
  );
  return res;
};

export const completeManufacturingPlanningApi = async (
  completeManufacturing,
) => {
  try {
    const res = await axios.post(
      `edmu/mes/manufacturing/complete?id=${completeManufacturing.id}`,
      {
        completeDate: completeManufacturing.completeDate || undefined,
        timeZone: completeManufacturing.timeZone,
      },
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const cancelManufacturingPlanningApi = async (completeManufacturing) => {
  try {
    const res = await axios.post(
      `edmu/mes/manufacturing/cancel?id=${completeManufacturing.id}`,
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getManufacturingPlanningInforApi = async (
  getManufacturingInfo,
) => {
  try {
    const res = await axios.get('edmu/mes/manufacturing', {
      params: {
        id: getManufacturingInfo.id || undefined,
        'item-id': getManufacturingInfo.itemTypeId || undefined,
        code: getManufacturingInfo.code || undefined,
        'due-date[start]': getManufacturingInfo.dueDateStart || null,
        'due-date[end]': getManufacturingInfo.dueDateEnd || null,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getManufacturingHistoryInforApi = async (getManufacturingInfo) => {
  try {
    const res = await axios.get('edmu/mes/manufacturing/history', {
      params: {
        id: getManufacturingInfo.id || undefined,
        'item-type-id': getManufacturingInfo.itemTypeId || undefined,
        'unit-id': getManufacturingInfo.unitId || undefined,
        'country-id': getManufacturingInfo.countryId || undefined,
        name: getManufacturingInfo.name || undefined,
        code: getManufacturingInfo.code || undefined,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteManufacturingHistoryApi = async (deleteManufacturingId) => {
  const res = await axios.delete(
    `edmu/mes/manufacturing/history?id=${deleteManufacturingId}`,
  );
  return res;
};

export const getAllManufacturingHistoryApi = async (
  getManufacturingHistoryInfor,
) => {
  try {
    const res = await axios.get('edmu/mes/manufacturing/history', {
      params: {
        'complete-date[start]':
          getManufacturingHistoryInfor.completeDateStart || null,
        'complete-date[end]':
          getManufacturingHistoryInfor.completeDateEnd || null,
        'time-zone': getManufacturingHistoryInfor.timeZone,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const postManufacturingPlanningBomApi = async (newBomItem) => {
  try {
    const res = await axios.post(`edmu/mes/manufacturing/bom`, {
      manufacturingId: newBomItem.manufacturingId,
      itemId: newBomItem.itemId,
      quantity: newBomItem.quantity,
      cost: newBomItem.cost,
      description: newBomItem.description,
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const patchManufacturingPlanningBomApi = async (modifyBomItem) => {
  try {
    const res = await axios.patch(
      `edmu/mes/manufacturing/bom?id=${modifyBomItem.id}`,
      {
        itemId: modifyBomItem.itemId,
        quantity: modifyBomItem.quantity,
        cost: modifyBomItem.cost,
        description: modifyBomItem.description,
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteManufacturingPlanningItemApi = async (
  deleteManufacturingItem,
) => {
  const res = await axios.delete(
    `edmu/mes/manufacturing/bom?id=${deleteManufacturingItem}`,
  );
  return res;
};

export const postReceivingPlanningApi = async (newReceiving) => {
  try {
    const res = await axios.post(`edmu/mes/receiving`, {
      workerId: newReceiving?.workerId || undefined,
      contactId: newReceiving?.contactId || undefined,
      startDate: newReceiving?.startDate || undefined,
      dueDate: newReceiving?.dueDate || undefined,
      totalPrice: newReceiving?.totalPrice || undefined,
      description: newReceiving?.description || undefined,
      itemData: newReceiving?.itemData || undefined,
      timeZone: newReceiving?.timeZone,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllReceivingPlanningApi = async (getReceivingPlanningInfor) => {
  try {
    const res = await axios.get('edmu/mes/receiving', {
      params: {
        'start-date[start]': getReceivingPlanningInfor.startDateStart || null,
        'start-date[end]': getReceivingPlanningInfor.startDateEnd || null,
        'due-date[start]': getReceivingPlanningInfor.dueDateStart || null,
        'due-date[end]': getReceivingPlanningInfor.dueDateEnd || null,
        'time-zone': getReceivingPlanningInfor.timeZone,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const patchReceivingPlanningApi = async (modifyReceivingInfo) => {
  try {
    const res = await axios.patch(
      `edmu/mes/receiving?id=${modifyReceivingInfo.id}`,
      modifyReceivingInfo.data,
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteReceivingPlanningApi = async (deleteReceivingId) => {
  const res = await axios.delete(`edmu/mes/receiving?id=${deleteReceivingId}`);
  return res;
};

export const completeReceivingPlanningApi = async (completeReceiving) => {
  try {
    const res = await axios.post(
      `edmu/mes/receiving/complete?id=${completeReceiving.id}`,
      {
        completeDate: completeReceiving.completeDate || undefined,
        timeZone: completeReceiving.timeZone,
      },
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const cancelReceivingPlanningApi = async (completeReceiving) => {
  try {
    const res = await axios.post(
      `edmu/mes/receiving/cancel?id=${completeReceiving.id}`,
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getReceivingPlanningInforApi = async (getReceivingInfo) => {
  try {
    const res = await axios.get('edmu/mes/receiving', {
      params: {
        id: getReceivingInfo.id || undefined,
        'item-type-id': getReceivingInfo.itemTypeId || undefined,
        'unit-id': getReceivingInfo.unitId || undefined,
        'country-id': getReceivingInfo.countryId || undefined,
        name: getReceivingInfo.name || undefined,
        code: getReceivingInfo.code || undefined,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const postReceivingPlanningItemApi = async (newReceiving) => {
  try {
    const res = await axios.post(`edmu/mes/receiving/item`, {
      receivingId: newReceiving.receivingId,
      itemId: newReceiving.itemId,
      quantity: newReceiving.quantity,
      unitPrice: newReceiving.unitPrice,
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const patchReceivingPlanningItemApi = async (modifyBomItem) => {
  try {
    const res = await axios.patch(
      `edmu/mes/receiving/item?id=${modifyBomItem.id}`,
      {
        itemId: modifyBomItem.itemId,
        quantity: modifyBomItem.quantity,
        unitPrice: modifyBomItem.unitPrice,
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteReceivingPlanningItemApi = async (deleteReceivingItem) => {
  const res = await axios.delete(
    `edmu/mes/receiving/item?id=${deleteReceivingItem}`,
  );
  return res;
};

export const getReceivingHistoryInforApi = async (getReceivingInfo) => {
  try {
    const res = await axios.get('edmu/mes/receiving/history', {
      params: {
        id: getReceivingInfo.id || undefined,
        'item-type-id': getReceivingInfo.itemTypeId || undefined,
        'unit-id': getReceivingInfo.unitId || undefined,
        'country-id': getReceivingInfo.countryId || undefined,
        name: getReceivingInfo.name || undefined,
        code: getReceivingInfo.code || undefined,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteReceivingHistoryApi = async (deleteReceivingId) => {
  const res = await axios.delete(
    `edmu/mes/receiving/history?id=${deleteReceivingId}`,
  );
  return res;
};

export const getAllReceivingHistoryApi = async (getReceivingHistoryInfor) => {
  try {
    const res = await axios.get('edmu/mes/receiving/history', {
      params: {
        'start-date[start]': getReceivingHistoryInfor.startDateStart || null,
        'start-date[end]': getReceivingHistoryInfor.startDateEnd || null,
        'due-date[start]': getReceivingHistoryInfor.dueDateStart || null,
        'due-date[end]': getReceivingHistoryInfor.dueDateEnd || null,
        'complete-date[start]':
          getReceivingHistoryInfor.completeDateStart || null,
        'complete-date[end]': getReceivingHistoryInfor.completeDateEnd || null,
        'time-zone': getReceivingHistoryInfor.timeZone,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};
export const postItemApi = async (newItemInfo) => {
  try {
    const formData = new FormData();
    formData.append('name', newItemInfo.name);
    formData.append('itemTypeId', newItemInfo.itemTypeId);
    formData.append('model', newItemInfo.model);

    if (newItemInfo.unitId) formData.append('unitId', newItemInfo.unitId);
    if (newItemInfo.countryId)
      formData.append('countryId', newItemInfo.countryId);
    if (newItemInfo.cost) formData.append('cost', newItemInfo.cost);
    if (newItemInfo.sellPrice)
      formData.append('sellPrice', newItemInfo.sellPrice);
    if (newItemInfo.description)
      formData.append('description', newItemInfo.description);
    if (newItemInfo.itemImage.length) {
      newItemInfo.itemImage.forEach((eachImageData) => {
        formData.append(
          'itemImage',
          imageToBlob(eachImageData.image),
          eachImageData.name,
        );
      });
    }

    const res = await axios.post('edmu/mes/item', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const getAllItemsApi = async () => {
  try {
    const res = await axios.get('edmu/mes/item');
    return res;
  } catch (error) {
    return error;
  }
};

export const getItemInforApi = async (getItemInfo) => {
  try {
    const res = await axios.get('edmu/mes/item', {
      params: {
        id: getItemInfo.id || undefined,
        'item-type-id': getItemInfo.itemTypeId || undefined,
        'unit-id': getItemInfo.unitId || undefined,
        'country-id': getItemInfo.countryId || undefined,
        name: getItemInfo.name || undefined,
        code: getItemInfo.code || undefined,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const patchItemApi = async (modifyItemInfo) => {
  try {
    const formData = new FormData();
    formData.append('name', modifyItemInfo.data.name);
    formData.append('itemTypeId', modifyItemInfo.data.itemTypeId);
    formData.append('model', modifyItemInfo.data.model);

    if (modifyItemInfo.data.unitId)
      formData.append('unitId', modifyItemInfo.data.unitId);
    if (modifyItemInfo.data.countryId)
      formData.append('countryId', modifyItemInfo.data.countryId);
    if (modifyItemInfo.data.cost)
      formData.append('cost', modifyItemInfo.data.cost);
    if (modifyItemInfo.data.sellPrice)
      formData.append('sellPrice', modifyItemInfo.data.sellPrice);
    if (modifyItemInfo.data.description)
      formData.append('description', modifyItemInfo.data.description);
    if (modifyItemInfo.isEditImage) {
      modifyItemInfo.data.itemImage.forEach((eachImageData) => {
        formData.append(
          'itemImage',
          imageToBlob(eachImageData.image),
          eachImageData.name,
        );
      });
    }

    const res = await axios.patch(
      `edmu/mes/item?id=${modifyItemInfo.id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return res;
  } catch (error) {
    return error;
  }
};

export const deleteItemApi = async (deleteItemId) => {
  const res = await axios.delete(`edmu/mes/item?id=${deleteItemId}`);
  return res;
};

export const postItemTypeApi = async (newItemType) => {
  try {
    const res = await axios.post(`edmu/mes/item-type`, {
      name: newItemType.name,
      description: newItemType.description,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getItemTypeApi = async () => {
  try {
    const res = await axios.get('edmu/mes/item-type');
    return res;
  } catch (error) {
    return error;
  }
};

export const patchItemTypeApi = async (modifyItemTypeInfo) => {
  try {
    const res = await axios.patch(
      `edmu/mes/item-type?id=${modifyItemTypeInfo.id}`,
      modifyItemTypeInfo.data,
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteItemTypeApi = async (deleteItemTypeId) => {
  const res = await axios.delete(`edmu/mes/item-type?id=${deleteItemTypeId}`);
  return res;
};

export const postItemUnitApi = async (newItemUnit) => {
  try {
    const res = await axios.post(`edmu/mes/unit`, {
      name: newItemUnit.name,
      description: newItemUnit.description,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getItemUnitApi = async () => {
  try {
    const res = await axios.get('edmu/mes/unit');
    return res;
  } catch (error) {
    return error;
  }
};

export const patchItemUnitApi = async (modifyItemUnitInfo) => {
  try {
    const res = await axios.patch(
      `edmu/mes/unit?id=${modifyItemUnitInfo.id}`,
      modifyItemUnitInfo.data,
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteItemUnitApi = async (deleteItemUnitId) => {
  const res = await axios.delete(`edmu/mes/unit?id=${deleteItemUnitId}`);
  return res;
};

export const postItemCountryApi = async (newItemCountry) => {
  try {
    const res = await axios.post(`edmu/mes/country`, {
      name: newItemCountry.name,
      description: newItemCountry.description,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getItemCountryApi = async () => {
  try {
    const res = await axios.get('edmu/mes/country');
    return res;
  } catch (error) {
    return error;
  }
};

export const patchItemCountryApi = async (modifyItemCountryInfo) => {
  try {
    const res = await axios.patch(
      `edmu/mes/country?id=${modifyItemCountryInfo.id}`,
      modifyItemCountryInfo.data,
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteItemCountryApi = async (deleteItemCountryId) => {
  const res = await axios.delete(`edmu/mes/country?id=${deleteItemCountryId}`);
  return res;
};

export const postBomInforApi = async (newBomInfor) => {
  try {
    const res = await axios.post(`edmu/mes/bom`, {
      itemId: newBomInfor.itemId,
      name: newBomInfor.name,
      totalPrice: newBomInfor.totalPrice,
      description: newBomInfor.description || undefined,
      bomItems: newBomInfor.bomItems,
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const getBomInforApi = async (getBomInfo) => {
  try {
    const res = await axios.get('edmu/mes/bom', {
      params: {
        id: getBomInfo.id || undefined,
        'item-id': getBomInfo.itemId || undefined,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const patchBomInforApi = async (modifyBomInfor) => {
  try {
    const res = await axios.patch(`edmu/mes/bom?id=${modifyBomInfor.id}`, {
      name: modifyBomInfor.name,
      totalPrice: modifyBomInfor.totalPrice,
      description: modifyBomInfor.description,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteBomListApi = async (deleteBomId) => {
  const res = await axios.delete(`edmu/mes/bom?id=${deleteBomId}`);
  return res;
};

export const postBomItemApi = async (newBomItem) => {
  try {
    const res = await axios.post(`edmu/mes/bom/item`, {
      itemId: newBomItem.itemId,
      bomId: newBomItem.bomId,
      cost: newBomItem.cost,
      quantity: newBomItem.quantity,
      description: newBomItem.description,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const patchBomItemApi = async (modifyBomItem) => {
  try {
    const res = await axios.patch(`edmu/mes/bom/item?id=${modifyBomItem.id}`, {
      itemId: modifyBomItem.itemId,
      cost: modifyBomItem.cost,
      quantity: modifyBomItem.quantity,
      description: modifyBomItem.description,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteBomItemApi = async (deleteBomItemId) => {
  const res = await axios.delete(`edmu/mes/bom/item?id=${deleteBomItemId}`);
  return res;
};

export const postContactApi = async (newContact) => {
  try {
    const res = await axios.post(`edmu/mes/contact`, {
      name: newContact.name,
      companyName: newContact.companyName,
      address: newContact.address,
      addressDetail: newContact.addressDetail,
      zipCode: newContact.zipCode,
      countryCode: newContact.countryCode,
      contactNumber: newContact.contactNumber,
      email: newContact.email,
      businessNumber: newContact.businessNumber,
      description: newContact.description,
      type: newContact.type,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getContactApi = async (getContactInfo) => {
  try {
    const res = await axios.get('edmu/mes/contact', {
      params: {
        id: getContactInfo?.id || undefined,
        name: getContactInfo?.name || undefined,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const patchContactApi = async (modifyContactInfo) => {
  try {
    const res = await axios.patch(
      `edmu/mes/contact?id=${modifyContactInfo.id}`,
      modifyContactInfo.data,
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteContactApi = async (deleteContactId) => {
  const res = await axios.delete(`edmu/mes/contact?id=${deleteContactId}`);
  return res;
};

export const getLotListApi = async (getLotList) => {
  try {
    const res = await axios.get(`edmu/mes/lot`, {
      params: {
        id: getLotList?.id || undefined,
        'item-id': getLotList?.itemId || undefined,
        'lot-number': getLotList?.lotNumber || undefined,
        available: getLotList?.available || true,
        assignable: getLotList?.assignable || true,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const postIncominfgPlanItemLotApi = async (newLotInfo) => {
  try {
    const res = await axios.post(
      `edmu/mes/lot/plan/incoming/item/${newLotInfo.type}`,
      {
        planId: newLotInfo.planId,
        planItemId: newLotInfo.planItemId,
        itemId: newLotInfo.itemId,
        quantity: newLotInfo.quantity,
        lotNumber: newLotInfo.lotNumber,
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getIncominfgPlanItemLotListApi = async (getLotInfo) => {
  try {
    const res = await axios.get(
      `edmu/mes/lot/plan/incoming/item/${getLotInfo.type}`,
      {
        params: {
          'plan-id': getLotInfo?.planId || undefined,
          'plan-item-id': getLotInfo?.planItemId || undefined,
          'item-id': getLotInfo?.itemId || undefined,
        },
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const patchIncominfgPlanItemLotApi = async (modifyBomItem) => {
  try {
    const res = await axios.patch(
      `edmu/mes/lot/plan/incoming/item/${modifyBomItem.type}?id=${modifyBomItem.id}`,
      {
        lotNumber: modifyBomItem.lotNumber,
        quantity: modifyBomItem.quantity,
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteIncominfgPlanItemLotApi = async (deleteLotId, type) => {
  const res = await axios.delete(
    `edmu/mes/lot/plan/item/${type}?id=${deleteLotId}`,
  );
  return res;
};

export const postOutgoingPlanItemLotApi = async (newLotInfo) => {
  try {
    const res = await axios.post(
      `edmu/mes/lot/plan/outgoing/item/${newLotInfo.type}`,
      {
        planId: newLotInfo.planId,
        planItemId: newLotInfo.planItemId,
        itemId: newLotInfo.itemId,
        quantity: newLotInfo.quantity,
        lotId: newLotInfo.lotId,
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getOutgoingPlanItemLotListApi = async (getOutgoingLotInfo) => {
  try {
    const res = await axios.get(
      `edmu/mes/lot/plan/outgoing/item/${getOutgoingLotInfo.type}`,
      {
        params: {
          'plan-id': getOutgoingLotInfo?.planId || undefined,
          'plan-item-id': getOutgoingLotInfo?.planItemId || undefined,
          'item-id': getOutgoingLotInfo?.itemId || undefined,
        },
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const patchOutgoingPlanItemLotApi = async (modifyItemLot) => {
  try {
    const res = await axios.patch(
      `edmu/mes/lot/plan/outgoing/item/${modifyItemLot.type}?id=${modifyItemLot.id}`,
      {
        lotId: modifyItemLot.lotId,
        quantity: modifyItemLot.quantity,
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getStockListApi = async (getStockInfo) => {
  try {
    const res = await axios.get(`edmu/mes/stock`, {
      params: {
        'item-id': getStockInfo?.itemId || undefined,
        'stock-only': getStockInfo?.stockOnly || false,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const postStockAdjustmentApi = async (newStockAdjustmentInfo) => {
  try {
    const res = await axios.post(`edmu/mes/stock/adjustment`, {
      lotId: newStockAdjustmentInfo.lotId,
      adjustmentQuantity: newStockAdjustmentInfo.adjustmentQuantity,
      description: newStockAdjustmentInfo.description,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getStockAdjustmentHistoryApi = async (
  getStockAdjustmentHistoryInfor,
) => {
  try {
    const res = await axios.get(`edmu/mes/stock/adjustment/history`, {
      params: {
        id: getStockAdjustmentHistoryInfor?.id || undefined,
        'item-id': getStockAdjustmentHistoryInfor?.itemId || undefined,
        start: getStockAdjustmentHistoryInfor?.start || undefined,
        end: getStockAdjustmentHistoryInfor?.end || undefined,
        'sort-type': getStockAdjustmentHistoryInfor?.sortType || undefined,
        'time-zone': getStockAdjustmentHistoryInfor?.timeZone || undefined,
        limit: getStockAdjustmentHistoryInfor?.limit || undefined,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getStockTraceApi = async (getStockTraceInfo) => {
  try {
    const res = await axios.get(`edmu/mes/stock/trace`, {
      params: {
        'item-id': getStockTraceInfo?.itemId,
        'lot-id': getStockTraceInfo?.lotId,
        type: getStockTraceInfo?.stockOnly || undefined,
        'complete-date[start]':
          getStockTraceInfo?.completeStartDate || undefined,
        'complete-date[end]': getStockTraceInfo?.completeENdDate || undefined,
        'time-zone': getStockTraceInfo?.timeZone || 'Asia/Seoul',
        'sort-type': getStockTraceInfo?.sortType || undefined,
        limit: getStockTraceInfo?.limit || undefined,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const postMesItemDetailApi = async (newItemDetailData) => {
  try {
    await axios.post(`edmu/mes/item/detail`, {
      itemId: newItemDetailData.itemId,
      detailData: newItemDetailData.detailData.map((eachDetailData) => {
        return {
          key: eachDetailData.key,
          value: eachDetailData.value,
          type: 'text',
        };
      }),
    });
    return true;
  } catch (error) {
    return error;
  }
};

export const patchMesItemDetailApi = async (modifyItemItemDetail) => {
  try {
    await axios.patch('edmu/mes/item/detail', {
      itemId: modifyItemItemDetail.itemId,
      detailData: modifyItemItemDetail.detailData.map((eachDetailData) => {
        return {
          id: eachDetailData.id,
          key: eachDetailData.key,
          value: eachDetailData.value,
        };
      }),
    });
    return true;
  } catch (error) {
    return error;
  }
};

export const deleteMesItemDetailApi = async (itemId, deleteItemDetailId) => {
  const res = await axios.delete(
    `edmu/mes/item/detail?id=${deleteItemDetailId}&item-id=${itemId}`,
  );
  return res;
};
