import { makeAutoObservable } from 'mobx';
import { getCountryByLanguage } from 'src/utils/countries';
import { getFrontendVersion } from 'src/api/util';

const moment = require('moment-timezone');

const findTimezone = () => {
  return moment.tz.guess();
};

class utilStore {
  timezone = '';

  previousRouting = localStorage.getItem('previousRouting') || null;

  defaultCountry = 'KR';

  sidebarOpen = localStorage.getItem('sidebarOpenStatus')
    ? JSON.parse(localStorage.getItem('sidebarOpenStatus'))
    : true;

  statusBoardMenuOpen = localStorage.getItem('statusBoardMenuOpenStatus')
    ? JSON.parse(localStorage.getItem('statusBoardMenuOpenStatus'))
    : true;

  pageTabIndex = localStorage.getItem('pageTabIndex') || 0;

  isTabIndexKeep = localStorage.getItem('isTabIndexKeep')
    ? localStorage.getItem('isTabIndexKeep').toLowerCase() === 'true'
    : false;

  frontendVersionByDb = '';

  constructor() {
    makeAutoObservable(this);
    // const locale = new Intl.DateTimeFormat().resolvedOptions().locale;    /* OS에 설정된 언어에 따라 동작하는 코드 */
    // localStorage.setItem('language', locale.split('-')?.[0]);

    if (!localStorage.getItem('language')) {
      localStorage.setItem(
        'language',
        navigator.language.split('-')?.[0],
      ); /* 브라우저에 설정된 언어에 따라 동작하는 코드 */
    }

    this.setTimezone(findTimezone());
    this.setDefaultCountry(getCountryByLanguage());
  }

  setTimezone = (timezone) => {
    this.timezone = timezone;
  };

  setPreviousRouting = (previousRouting) => {
    localStorage.setItem('previousRouting', previousRouting);
    this.previousRouting = previousRouting;
  };

  setIsTabIndexKeep = (isTabIndexKeep) => {
    localStorage.setItem('isTabIndexKeep', isTabIndexKeep);
    this.isTabIndexKeep = isTabIndexKeep;
  };

  getTimezone = () => {
    return this.timezone;
  };

  setDefaultCountry = (country) => {
    this.defaultCountry = country;
  };

  setSidebarOpen = (status) => {
    localStorage.setItem('sidebarOpenStatus', status);
    this.sidebarOpen = status;
  };

  setStatusBoardMenuOpen = (status) => {
    localStorage.setItem('statusBoardMenuOpenStatus', status);
    this.statusBoardMenuOpen = status;
  };

  checkFrontendVersion = async () => {
    this.frontendVersionByDb = await getFrontendVersion();
    if (this.frontendVersionByDb !== '') return true;
    return false;
  };
}

export default utilStore;
