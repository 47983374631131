import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';
import { FooterP, FooterA } from '../style';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className,
  );

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider',
          )}
        >
          <div className="footer-top space-between text-xxs">
            <Logo />
            <FooterSocial />
          </div>
          <div
            className="footer-bottom text-xxs invert-order-desktop" /* align="left" */
          >
            <FooterNav />
            {/* <FooterP className="address">
              3003 North First St. Ansan, Gyeonggi, South Korea.
            </FooterP>
            <FooterP className="address">San Jose, CA 95134, USA</FooterP>
            <FooterP className="address">503, BI, 155 Ansandaehak-ro</FooterP> */}
            <FooterP className="address">
              대한민국 경기도 안산시 상록구 안산대학로 155 창업보육센터 503호
            </FooterP>
            <FooterP className="contanct">
              070-8680-8368 info@unirobotics.co.kr
            </FooterP>
            <div className="footer-copyright">
              <FooterA href="https://www.unirobotics.co.kr/">
                Unirobotics Inc.
              </FooterA>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
