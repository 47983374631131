import React from 'react';
import { Card, Grid, styled, Button, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { HeroH2, HeroP } from '../style';

const TemporaryInfoBox = styled(Card)(
  ({ theme }) => `
      overflow: hidden;
      position: relative;
      padding: ${theme.spacing(4)};
      @media (max-width: 599px) {
        padding: ${theme.spacing(0.5)};
     }
`,
);

const AdvantagesPage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );
  const mobile = useMediaQuery('(max-width: 1319px)');

  return (
    <section {...props} className={outerClasses}>
      <div className="reveal-from-bottom" data-reveal-delay="200">
        <TemporaryInfoBox>
          {mobile ? (
            <div
              style={{
                marginTop: '-0.25rem',
              }}
            >
              <Grid container justifyContent={'center'}>
                {/* <HeroH2>Platform advantages</HeroH2> */}
                <HeroH2>플랫폼의 장점</HeroH2>
              </Grid>
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  marginBottom: '3rem',
                }}
                src={'/static/images/info/advantages_kor.png'}
                alt="FeaturesIcon"
              />
              {/* <HeroP style={{ padding: '1rem' }}>
                The Okomo Enterprise Enegry Platform has been designed with
                heavy-duty, enterprise-grade enegry solutions in mind. It
                banishes a monolithic approach to architecture in favour of
                highly portable microservices, which allow for flexible
                rearrangement and customization even in the middle of the
                solution's lifecycle. It enables cloud-native scalability and
                resilience while offering full freedom in regard to the type of
                deployment. Combined with its technology-agnostic attitude
                towards development and DevOps, Okomo Enterprise puts you in
                charge of your enegry innovations by holding out extensive
                freedom of technology, deployment models, and customiza
              </HeroP> */}
              <HeroP style={{ padding: '1rem' }}>
                Okomo 플랫폼은 대규모, 엔터프라이즈급 에너지 솔루션을 염두에
                두고 설계되었습니다. 이 플랫폼은 기존의 모놀리식 아키텍처 접근
                방식을 배제하고, 유연하게 구성 변경 및 조정이나 맞춤 설정이
                가능한 고도로 이식성 있는 마이크로서비스를 채택했습니다. 이를
                통해 플랫폼을 사용하면서도 원하는 구성을 언제든지 자유롭게
                변경할 수 있습니다. 또한, 클라우드 네이티브 확장성과 복원성을
                지원하며, 배포 방식에 대한 완전한 자유를 제공합니다. 기술 개발과
                DevOps에 있어 특정 기술에 구애받지 않는 접근 방식을 결합하여
                Okomo 플랫폼은 기술, 배포 모델, 맞춤화의 광범위한 자유를
                제공함으로써 귀사의 에너지 혁신을 주도할 수 있도록 합니다.
              </HeroP>
              <div className="reveal-from-bottom" data-reveal-delay="200">
                <div>
                  <Grid container justifyContent={'center'}>
                    <Button
                      style={{
                        padding: '1rem',
                        marginTop: '2rem',
                        marginBottom: '2.5rem',
                      }}
                      variant="contained"
                      wideMobile
                    >
                      {/* Learn more */}더 알아보기
                    </Button>
                  </Grid>
                </div>
              </div>
            </div>
          ) : (
            <Grid container justifyContent={'center'}>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    marginTop: '-3rem',
                    marginBottom: '3rem',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '400px',
                  }}
                >
                  {/* <HeroH2>Platform advantages</HeroH2> */}
                  <HeroH2>플랫폼의 장점</HeroH2>
                  {/* <HeroP>
                    The Okomo Enterprise Enegry Platform has been designed with
                    heavy-duty, enterprise-grade enegry solutions in mind. It
                    banishes a monolithic approach to architecture in favour of
                    highly portable microservices, which allow for flexible
                    rearrangement and customization even in the middle of the
                    solution's lifecycle. It enables cloud-native scalability
                    and resilience while offering full freedom in regard to the
                    type of deployment. Combined with its technology-agnostic
                    attitude towards development and DevOps, Okomo Enterprise
                    puts you in charge of your enegry innovations by holding out
                    extensive freedom of technology, deployment models, and
                    customiza
                  </HeroP> */}
                  <HeroP>
                    Okomo 플랫폼은 대규모, 엔터프라이즈급 에너지 솔루션을 염두에
                    두고 설계되었습니다. 이 플랫폼은 기존의 모놀리식 아키텍처
                    접근 방식을 배제하고, 유연하게 구성 변경 및 조정이나 맞춤
                    설정이 가능한 고도로 이식성 있는 마이크로서비스를
                    채택했습니다. 이를 통해 플랫폼을 사용하면서도 원하는 구성을
                    언제든지 자유롭게 변경할 수 있습니다. 또한, 클라우드
                    네이티브 확장성과 복원성을 지원하며, 배포 방식에 대한 완전한
                    자유를 제공합니다. 기술 개발과 DevOps에 있어 특정 기술에
                    구애받지 않는 접근 방식을 결합하여 Okomo 플랫폼은 기술, 배포
                    모델, 맞춤화의 광범위한 자유를 제공함으로써 귀사의 에너지
                    혁신을 주도할 수 있도록 합니다.
                  </HeroP>
                  <div className="reveal-from-bottom" data-reveal-delay="200">
                    <div>
                      <Button
                        style={{ padding: '1rem', marginTop: '5rem' }}
                        variant="contained"
                        wideMobile
                      >
                        {/* Learn more */}더 알아보기
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <img
                style={{
                  width: '50rem',
                  height: '52.5rem',
                  marginLeft: '3rem',
                }}
                src={'/static/images/info/advantages_kor.png'}
                alt="FeaturesIcon"
              />
            </Grid>
          )}
        </TemporaryInfoBox>
      </div>
    </section>
  );
};

export default AdvantagesPage;
