import { useRef, useEffect } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import { SidebarLayoutWrapper, ContentWrapper } from './style';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/mobx-store';

const SidebarLayout = observer(() => {
  const contentRef = useRef(null);
  const { utilStore } = useStore();
  const { setSidebarOpen } = utilStore;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        mobile &&
        contentRef.current &&
        contentRef.current.contains(event.target)
      ) {
        setSidebarOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contentRef, mobile]);

  return (
    <SidebarLayoutWrapper>
      <Header />
      <Sidebar />
      <ContentWrapper ref={contentRef}>
        <Box className="content_box" display="block">
          <Outlet />
        </Box>
      </ContentWrapper>
    </SidebarLayoutWrapper>
  );
});

export default SidebarLayout;
