import React from 'react';
import classNames from 'classnames';
import { FooterA } from '../../style';

const FooterNav = ({ className, ...props }) => {
  const classes = classNames('footer-nav', className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          {/* <FooterA href={'https://www.unirobotics.co.kr/'}>Contact</FooterA> */}
          <FooterA href={'https://www.unirobotics.co.kr/'}>문의하기</FooterA>
        </li>
        <li>
          {/* <FooterA href={'https://www.unirobotics.co.kr/'}>About us</FooterA> */}
          <FooterA href={'https://www.unirobotics.co.kr/'}>회사 소개</FooterA>
        </li>
        <li>
          {/* <FooterA href={'https://www.unirobotics.co.kr/'}>FAQ's</FooterA> */}
          <FooterA href={'https://www.unirobotics.co.kr/'}>
            자주 묻는 질문
          </FooterA>
        </li>
        <li>
          {/* <FooterA href={'https://www.unirobotics.co.kr/'}>Support</FooterA> */}
          <FooterA href={'https://www.unirobotics.co.kr/'}>고객 지원</FooterA>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
