import React from 'react';
import { Box, Card, styled, Button, Grid, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { CoverHeroH2, CoverP } from '../style';
import { useTranslation } from 'react-i18next';

const CoverImage = styled(Box)(
  () => `
      background-position: 0;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 70vw;
      @media (max-width: 599px) {
        width: 135vw;
        height: 67%;
      }
  `,
);

const TemporaryInfoBox = styled(Card)(
  ({ theme }) => `
  padding: ${theme.spacing(4)};
  overflow: hidden;
  position: relative;
  height: 30rem;
  @media (max-width: 599px) {
    height: 50rem;
  }
`,
);

const CoverImagePage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );
  const tablet = useMediaQuery('(max-width: 1172px)');
  const mobile = useMediaQuery('(max-width: 599px)');
  const { t } = useTranslation();
  const prefix = '소개_';

  return (
    <section {...props} className={outerClasses}>
      <div className="reveal-from-bottom" data-reveal-delay="300">
        <TemporaryInfoBox>
          <CoverImage
            sx={{
              backgroundImage:
                'url("/static/images/placeholders/covers/info-bg.png")',
            }}
          />
          <div
            style={{
              marginTop: '-3rem',
              marginBottom: '3rem',
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '1300px',
            }}
          >
            <CoverHeroH2
              style={{
                marginTop: mobile && '1.5rem',
                marginLeft: '0.025rem',
              }}
            >
              {t(`${prefix}Okomo 플램폿과 함께`)}
            </CoverHeroH2>
            <CoverHeroH2 style={{ marginTop: mobile ? '-2rem' : '-1rem' }}>
              {t(`${prefix}귀사의 사업 솔루션 설계를 시작하세요`)}
            </CoverHeroH2>
            {tablet ? (
              mobile ? (
                <div style={{ paddingTop: '25.5rem' }}>
                  <CoverP>
                    {t(
                      `${prefix}설비 구성에 도움이 필요하신가요? 엔지니어링 지원을 신청하세요,`,
                    )}
                  </CoverP>
                  <CoverP>
                    {t(
                      `${prefix}자사의 전문가가 최적의 솔루션을 제시해드립니다.`,
                    )}
                  </CoverP>
                  <div style={{ padding: '2rem' }}>
                    <Grid container justifyContent={'center'}>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          style={{
                            height: '55px',
                            fontSize: '0.75rem',
                            padding: '0.85rem',
                            wordBreak: 'keep-all',
                            whiteSpace: 'normal',
                          }}
                          variant="outlined"
                          wideMobile
                        >
                          {t(`${prefix}상담 신청`)}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          style={{
                            height: '55px',
                            fontSize: '0.75rem',
                            padding: '0.85rem',
                            marginLeft: '1rem',
                            wordBreak: 'keep-all',
                            whiteSpace: 'normal',
                          }}
                          variant="contained"
                          wideMobile
                        >
                          {t(`${prefix}OKOMO 체험하기`)}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ) : (
                <div style={{ width: '19.5rem' }}>
                  <CoverP>
                    {t(
                      `${prefix}설비 구성에 도움이 필요하신가요? 엔지니어링 지원을 신청하세요,`,
                    )}
                  </CoverP>
                  <CoverP>
                    {t(
                      `${prefix}자사의 전문가가 최적의 솔루션을 제시해드립니다.`,
                    )}
                  </CoverP>
                  <div style={{ padding: '2rem', marginLeft: '-2rem' }}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Button
                          style={{
                            width: '150px',
                            height: '55px',
                            fontSize: '0.7rem',
                            padding: '1rem',
                          }}
                          variant="outlined"
                          wideMobile
                        >
                          {t(`${prefix}상담 신청`)}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          style={{
                            width: '150px',
                            height: '55px',
                            fontSize: '0.7rem',
                            padding: '1rem',
                            marginLeft: '1rem',
                          }}
                          variant="contained"
                          wideMobile
                        >
                          {t(`${prefix}OKOMO 체험하기`)}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )
            ) : (
              <>
                <CoverP>
                  {t(
                    `${prefix}설비 구성에 도움이 필요하신가요? 엔지니어링 지원을 신청하세요,`,
                  )}
                </CoverP>
                <CoverP>
                  {t(
                    `${prefix}자사의 전문가가 최적의 솔루션을 제시해드립니다.`,
                  )}
                </CoverP>
                <div className="reveal-from-bottom" data-reveal-delay="450">
                  <div style={{ marginTop: '2.5rem' }}>
                    <Button
                      style={{
                        width: '220px',
                        padding: '0.75rem',
                        fontSize: '1.1rem',
                      }}
                      variant="outlined"
                      wideMobile
                    >
                      {t(`${prefix}상담 신청`)}
                    </Button>
                    <Button
                      style={{
                        width: '220px',
                        padding: '0.75rem',
                        fontSize: '1.1rem',
                        marginLeft: '1rem',
                      }}
                      variant="contained"
                      wideMobile
                    >
                      {t(`${prefix}OKOMO 체험하기`)}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </TemporaryInfoBox>
      </div>
    </section>
  );
};

export default CoverImagePage;
