import { Navigate } from 'react-router-dom';
import StatusComingSoon from 'src/pages/exception/ComingSoon';
import { Loader, retryLazy } from 'src/utils/lazyUtil';

const Dashboard = Loader(retryLazy(() => import('src/pages/mes/Dashboard')));

const Shipping = Loader(retryLazy(() => import('src/pages/mes/Shipping')));

const ShippingPlanningInfor = Loader(
  retryLazy(() =>
    import('src/pages/mes/Shipping/layout/ShippingPlanningInfor'),
  ),
);

const ShippingHistoryInfor = Loader(
  retryLazy(() => import('src/pages/mes/Shipping/layout/ShippingHistoryInfor')),
);

const Manufacturing = Loader(
  retryLazy(() => import('src/pages/mes/Manufacturing')),
);

const ManufacturingPlanningInfor = Loader(
  retryLazy(() =>
    import('src/pages/mes/Manufacturing/layout/ManufacturingPlanningInfor'),
  ),
);

const ManufacturingHistoryInfor = Loader(
  retryLazy(() =>
    import('src/pages/mes/Manufacturing/layout/ManufacturingHistoryInfor'),
  ),
);

const Receiving = Loader(retryLazy(() => import('src/pages/mes/Receiving')));

const ReceivingPlanningInfor = Loader(
  retryLazy(() =>
    import('src/pages/mes/Receiving/layout/ReceivingPlanningInfor'),
  ),
);

const ReceivingHistoryInfor = Loader(
  retryLazy(() =>
    import('src/pages/mes/Receiving/layout/ReceivingHistoryInfor'),
  ),
);

const ItemList = Loader(
  retryLazy(() => import('src/pages/mes/Item/layout/ItemList')),
);

const ItemInfor = Loader(
  retryLazy(() => import('src/pages/mes/Item/layout/ItemInfor')),
);

const BomInfor = Loader(
  retryLazy(() => import('src/pages/mes/Item/layout/BomInfor')),
);

const StockList = Loader(retryLazy(() => import('src/pages/mes/Stock')));

const LotList = Loader(retryLazy(() => import('src/pages/mes/Lot')));

const LotInfor = Loader(
  retryLazy(() => import('src/pages/mes/Lot/layout/LotInfor')),
);

const mesRoutes = [
  {
    path: '',
    element: <Navigate to="item/list" replace />,
  },
  {
    path: 'dashboard',
    element: <Dashboard />,
  },
  {
    path: 'shipping/list',
    element: <Shipping />,
  },
  {
    path: 'shipping/registration',
    element: <ShippingPlanningInfor />,
  },
  {
    path: 'shipping/planning/:shippingId',
    element: <ShippingPlanningInfor />,
  },
  {
    path: 'shipping/history/:shippingId',
    element: <ShippingHistoryInfor />,
  },
  {
    path: 'manufacturing/list',
    element: <Manufacturing />,
  },
  {
    path: 'manufacturing/registration',
    element: <ManufacturingPlanningInfor />,
  },
  {
    path: 'manufacturing/planning/:manufacturingId',
    element: <ManufacturingPlanningInfor />,
  },
  {
    path: 'manufacturing/history/:manufacturingId',
    element: <ManufacturingHistoryInfor />,
  },
  {
    path: 'receiving/list',
    element: <Receiving />,
  },
  {
    path: 'receiving/registration',
    element: <ReceivingPlanningInfor />,
  },
  {
    path: 'receiving/planning/:receivingId',
    element: <ReceivingPlanningInfor />,
  },
  {
    path: 'receiving/history/:receivingId',
    element: <ReceivingHistoryInfor />,
  },
  {
    path: 'outgoing/list',
    element: <Shipping onlyHistory />,
  },
  {
    path: 'outgoing/history/:shippingId',
    element: <ShippingHistoryInfor onlyHistory />,
  },
  {
    path: 'stock/list',
    element: <StockList />,
  },
  {
    path: 'stock/lot/list/:itemId',
    element: <LotList />,
  },
  {
    path: 'stock/lot/view/:lotId',
    element: <LotInfor />,
  },
  {
    path: 'stock/lot/view/:lotId/:lotQuantity/:itemUnit/:itemCode/:itemName/:itemType',
    element: <LotInfor />,
  },
  {
    path: 'item/list',
    element: <ItemList />,
  },
  {
    path: 'item/registration',
    element: <ItemInfor />,
  },
  {
    path: 'item/view/:itemId',
    element: <ItemInfor />,
  },
  {
    path: 'bom/registration',
    element: <BomInfor />,
  },
  {
    path: 'bom/registration/:itemId',
    element: <BomInfor />,
  },
  {
    path: 'bom/view/:itemId/:bomId',
    element: <BomInfor />,
  },
  {
    path: 'contact/list',
    element: <StatusComingSoon />,
  },
];

export default mesRoutes;
