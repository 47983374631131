import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { HeroH1, HeroH5, HeroSpan, WordBreakTypography } from '../style';
import { observer } from 'mobx-react-lite';
import { Box, styled, Grid, useTheme, useMediaQuery } from '@mui/material';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const FeaturesIcon = styled(Box)(
  () => `
        height: auto;
        width: 100%;
        text-align: center;
    
        img {
          max-width: 158px;
        }
    `,
);

const FeaturesIconPage = observer(
  ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {
    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const prefix = '소개_';

    return (
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses}>
            <div
              className="hero-content reveal-from-bottom"
              data-reveal-delay="450"
            >
              <HeroH1 className="mt-0 mb-8 ">
                {/* The most flexible <HeroSpan>energy platform</HeroSpan> for your
                business */}
                당신의 비즈니스를 위한 가장 유연한
                {/* The most flexible <HeroSpan>energy platform</HeroSpan> for your
                business */}
              </HeroH1>
              <HeroH1 className="mt-0 mb-8 ">
                <HeroSpan>에너지 플랫폼</HeroSpan>
              </HeroH1>
              <Grid
                container
                spacing={mobile ? 2 : 5}
                style={{ marginTop: '3rem' }}
              >
                <Grid item xs={6} sm={6} md={4}>
                  <FeaturesIcon>
                    <img
                      style={{
                        width: '8rem',
                        height: '8rem',
                      }}
                      src={'/static/images/info/collect-1.svg'}
                      alt="FeaturesIcon"
                    />
                  </FeaturesIcon>
                  {/* <HeroH5>Collect and control</HeroH5> */}
                  <HeroH5>수집과 제어</HeroH5>
                  <WordBreakTypography variant="subtitle1" wrap>
                    사용자 인터페이스 및 REST API를 활용하여 클라우드를 통해 IoT
                    설비를 연결하고 관리합니다. 통합 대시보드나 Grafana와 같은
                    서드 파티 툴을 사용하여 원격 측정 데이터를 수집하고
                    모니터링합니다.
                  </WordBreakTypography>
                  {/* <WordBreakTypography variant="subtitle1" wrap>
                    Connect and manage IoT devices via the cloud using graphical
                    UI or REST API. Collect and visualize telemetry using a
                    built-in dashboard or 3rd party tools like Grafana.
                  </WordBreakTypography> */}
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <FeaturesIcon>
                    <img
                      style={{
                        width: '8rem',
                        height: '8rem',
                      }}
                      src={'/static/images/info/fast-1.svg'}
                      alt="FeaturesIcon"
                    />
                  </FeaturesIcon>
                  {/* <HeroH5>Fast, scalable, modern</HeroH5> */}
                  <HeroH5>빠르고, 확장 가능하며, 현대적인</HeroH5>
                  {/* <WordBreakTypography variant="subtitle1" wrap>
                    With IoT microservices architecture baked by Kubernetes, you
                    can scale infinitely, scale only what is needed, all with
                    extreme performance and fault-tolerance.
                  </WordBreakTypography> */}
                  <WordBreakTypography variant="subtitle1" wrap>
                    Kubernetes를 기반으로 구축한 IoT 마이크로서비스 아키텍처를
                    통해 필요한 만큼만 확장하거나 무한히 확장할 수 있으며, 이
                    모든 것이 최고의 성능과 내결함성을 갖추고 있습니다.
                  </WordBreakTypography>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <FeaturesIcon>
                    <img
                      style={{
                        width: '8rem',
                        height: '8rem',
                      }}
                      src={'/static/images/info/get-insight-1.svg'}
                      alt="FeaturesIcon"
                    />
                  </FeaturesIcon>
                  {/* <HeroH5>Get insights from your data</HeroH5> */}
                  <HeroH5>데이터를 통한 분석 </HeroH5>
                  {/* <WordBreakTypography variant="subtitle1" wrap>
                    Stream data from your IoT devices to any data analytics
                    system via pre-integrated channel. Change device
                    behavior based on processing results.
                  </WordBreakTypography> */}
                  <WordBreakTypography variant="subtitle1" wrap>
                    기본적으로 통합된 채널을 통해 IoT 설비의 데이터를 통합
                    데이터 분석 시스템으로 스트리밍합니다. 처리 결과에 따라 기기
                    동작을 변경할 수 있습니다.
                  </WordBreakTypography>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <FeaturesIcon>
                    <img
                      style={{
                        width: '8rem',
                        height: '8rem',
                      }}
                      src={'/static/images/info/created-expert-1.svg'}
                      alt="FeaturesIcon"
                    />
                  </FeaturesIcon>
                  {/* <HeroH5>Created by experts</HeroH5> */}
                  <HeroH5>전문가들이 개발한</HeroH5>
                  {/* <WordBreakTypography variant="subtitle1" wrap>
                    The company behind the platform has over 25 years of
                    experience in enegry, Telecom and Big Data. We used all our
                    profound knowledge to create the best Enegry Platform that
                    can serve big enterprises as well as small startups.
                  </WordBreakTypography> */}
                  <WordBreakTypography variant="subtitle1" wrap>
                    Okomo 플랫폼을 개발한 자사는 에너지, 통신, 빅데이터 분야에서
                    25년 이상의 경험을 보유하고 있습니다. 저희는 그동안 쌓아온
                    깊은 지식을 바탕으로 대기업과 소규모 스타트업을 모두 지원할
                    수 있는 최고의 에너지 플랫폼을 만들었습니다.
                  </WordBreakTypography>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <FeaturesIcon>
                    <img
                      style={{
                        width: '8rem',
                        height: '8rem',
                      }}
                      src={'/static/images/info/security-1.svg'}
                      alt="FeaturesIcon"
                    />
                  </FeaturesIcon>
                  {/* <HeroH5>Security & User Management</HeroH5> */}
                  <HeroH5>보안 & 사용자 관리</HeroH5>
                  {/* <WordBreakTypography variant="subtitle1" wrap>
                    All data is encrypted in transit and at rest. Out-of-box
                    authentication & authorization for users with open OAuth2 /
                    UMA interface.
                  </WordBreakTypography> */}
                  <WordBreakTypography variant="subtitle1" wrap>
                    모든 데이터는 전송 및 저장 중에 암호화됩니다. 또한 Firebase
                    인증 시스템을 통한 사용자 기본 인증 및 권한 부여 기능을
                    제공합니다.
                  </WordBreakTypography>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <FeaturesIcon>
                    <img
                      style={{
                        width: '8rem',
                        height: '8rem',
                      }}
                      src={'/static/images/info/full-control-1.svg'}
                      alt="FeaturesIcon"
                    />
                  </FeaturesIcon>
                  {/* <HeroH5>Full control</HeroH5> */}
                  <HeroH5>통합 제어</HeroH5>
                  {/* <WordBreakTypography variant="subtitle1" wrap>
                    You choose where to host the enegry platform: in the public
                    cloud, on your premises or even in both places at the same
                    time (multicloud).
                  </WordBreakTypography> */}
                  <WordBreakTypography variant="subtitle1" wrap>
                    Okomo 플랫폼을 어디에 호스팅할지 선택할 수 있습니다. 공용
                    클라우드, 자체 서버, 또는 두 곳을 동시에 활용하는 멀티
                    클라우드 환경으로도 구성이 가능합니다.
                  </WordBreakTypography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </section>
    );
  },
);

FeaturesIconPage.propTypes = propTypes;
FeaturesIconPage.defaultProps = defaultProps;

export default FeaturesIconPage;
