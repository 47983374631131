import React from 'react';
import PageHelmet from 'src/components/PageHelmet';
import Header from 'src/components/Header';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import Label from 'src/components/Label';
import { Fragment } from 'react';
import { Content, ContentWrapper, MainContent } from './style';

const ListItemButtonWrapper = styled(ListItemButton)(
  ({ theme }) => `
      transition: ${theme.transitions.create('all')};
      background: ${theme.colors.alpha.white[100]};
      position: relative;
      z-index: 8;
      border-radius: 0;
  
      &:hover {
          background: ${alpha(theme.colors.primary.main, 0.03)};
          z-index: 9;
          box-shadow: 
              0 0.56875rem 2.3rem ${alpha(theme.colors.alpha.black[100], 0.03)},
              0 0.9975rem 1.4rem ${alpha(theme.colors.alpha.black[100], 0.04)},
              0 0.35rem .5rem ${alpha(theme.colors.alpha.black[100], 0.05)},
              0 0.225rem 0.4rem ${alpha(theme.colors.alpha.black[100], 0.07)};
      }
    `,
);

const Pricing = () => {
  const { t } = useTranslation();

  const liteItems = [
    {
      id: 1,
      title: '최대 사용자',
      value: '1 User',
      user: true,
    },
    {
      id: 2,
      title: '재고관리(MRP)',
      value: 'O',
    },
    {
      id: 3,
      title: '실시간 리포팅',
      value: 'X',
      budget: ' ',
    },
    {
      id: 4,
      title: '실시간 모니터링',
      value: 'X',
      budget: ' ',
    },
    {
      id: 5,
      title: 'KPI 연동',
      value: 'X',
      budget: ' ',
    },
    {
      id: 6,
      title: '센서 연동 기능',
      value: 'X',
      budget: ' ',
    },
    {
      id: 7,
      title: '원격 제어',
      value: 'X',
      budget: ' ',
    },
    {
      id: 8,
      title: '장비 리포팅',
      value: 'X',
      budget: ' ',
    },
    {
      id: 9,
      title: '설비 유지 관리',
      value: 'X',
      budget: ' ',
    },
    {
      id: 10,
      title: '통합 유지 보수 관리',
      value: 'X',
      budget: ' ',
    },
    {
      id: 11,
      title: '현장 실시간 모니터링',
      value: 'X',
      budget: ' ',
    },
    {
      id: 12,
      title: '생산 분석 서비스',
      value: 'X',
      budget: ' ',
    },
    {
      id: 13,
      title: '출장 A/S',
      value: 'X',
      budget: ' ',
    },
  ];

  const standardItems = [
    {
      id: 1,
      title: '최대 사용자',
      value: '10 User',
      user: true,
    },
    {
      id: 2,
      title: '재고관리(MRP)',
      value: 'O',
    },
    {
      id: 3,
      title: '실시간 리포팅',
      value: 'O',
    },
    {
      id: 4,
      title: '실시간 모니터링',
      value: 'O',
    },
    {
      id: 5,
      title: 'KPI 연동',
      value: 'O',
    },
    {
      id: 6,
      title: '센서 연동 기능',
      value: 'X',
      budget: ' ',
    },
    {
      id: 7,
      title: '원격 제어',
      value: 'X',
      budget: ' ',
    },
    {
      id: 8,
      title: '장비 리포팅',
      value: 'X',
      budget: ' ',
    },
    {
      id: 9,
      title: '설비 유지 관리',
      value: 'X',
      budget: ' ',
    },
    {
      id: 10,
      title: '통합 유지 보수 관리',
      value: 'X',
      budget: ' ',
    },
    {
      id: 11,
      title: '현장 실시간 모니터링',
      value: 'X',
      budget: ' ',
    },
    {
      id: 12,
      title: '생산 분석 서비스',
      value: 'X',
      budget: ' ',
    },
    {
      id: 13,
      title: '출장 A/S',
      value: 'X',
      budget: ' ',
    },
  ];

  const premiumItems = [
    {
      id: 1,
      title: '최대 사용자',
      value: '20 User',
      user: true,
    },
    {
      id: 2,
      title: '재고관리(MRP)',
      value: 'O',
    },
    {
      id: 3,
      title: '실시간 리포팅',
      value: 'O',
    },
    {
      id: 4,
      title: '실시간 모니터링',
      value: 'O',
    },
    {
      id: 5,
      title: 'KPI 연동',
      value: 'O',
    },
    {
      id: 6,
      title: '센서 연동 기능',
      value: 'O',
    },
    {
      id: 7,
      title: '원격 제어',
      value: 'O',
    },
    {
      id: 8,
      title: '장비 리포팅',
      value: 'O',
    },
    {
      id: 9,
      title: '설비 유지 관리',
      value: 'O',
    },
    {
      id: 10,
      title: '통합 유지 보수 관리',
      value: 'O',
    },
    {
      id: 11,
      title: '현장 실시간 모니터링',
      value: 'O',
    },
    {
      id: 12,
      title: '생산 분석 서비스',
      value: 'O',
    },
    {
      id: 13,
      title: '출장 A/S',
      value: 'O',
    },
  ];

  return (
    <>
      <PageHelmet message={'요금제'} />
      <div id="information-container">
        <Header navPosition="right" className="reveal-from-bottom" />
      </div>
      {/* <Content>
        <MainContent>
          <FormWrapper maxWidth="sm">
            <ContentWrapper variant="outlined">
              <Box textAlign="center"> */}
      <Content>
        <MainContent>
          <ContentWrapper>
            <Grid container justifyContent="center">
              <Typography
                style={{
                  justifyContent: 'flex-start',
                  marginBottom: '2.5rem',
                }}
                className="main_title"
                variant="h2"
              >
                {t('필요에 따라 적합한 최적의 플랜을 선택하세요')}
              </Typography>
            </Grid>
            <Grid
              className="content"
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid
                item
                lg={3}
                xl={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexGrow={1}
                  sx={{
                    border: 1,
                    borderColor: '#909090',
                    borderWidth: '1.5px',
                    borderRadius: '5px',
                    padding: '16px',
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        textAlign: 'center',
                        width: '200px',
                      }}
                    >
                      <Typography variant="h1" sx={{ color: '#909090' }}>
                        LITE
                      </Typography>
                      <Typography
                        sx={{
                          pt: 1,
                          color: '#909090',
                        }}
                        variant="subtitle2"
                        textAlign="center"
                      >
                        {t('137,500원 / 1개월 (VAT 포함)')}
                      </Typography>
                    </Box>
                    <List disablePadding>
                      {liteItems.map((item) => (
                        <Fragment key={item.id}>
                          <Divider />
                          <ListItemButtonWrapper
                            sx={{
                              display: { xs: 'block', sm: 'flex' },
                              py: 2,
                              px: 2.5,
                            }}
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography color="text.primary" variant="h5">
                                  {item.title}
                                </Typography>
                              }
                            />
                            <Box
                              component="span"
                              sx={{
                                display: { xs: 'none', sm: 'inline-block' },
                              }}
                            >
                              <Box textAlign="right">
                                {item.user ? (
                                  <Label
                                    style={{
                                      color: 'black',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {item.value}
                                  </Label>
                                ) : item.budget ? (
                                  <Label color="error">{item.value}</Label>
                                ) : (
                                  <Label color="success">{item.value}</Label>
                                )}
                              </Box>
                            </Box>
                          </ListItemButtonWrapper>
                        </Fragment>
                      ))}
                    </List>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                lg={3}
                xl={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexGrow={1}
                  sx={{
                    border: 1,
                    borderColor: 'black',
                    borderWidth: '1.5px',
                    borderRadius: '5px',
                    padding: '16px',
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        textAlign: 'center',
                        width: '200px',
                      }}
                    >
                      <Typography variant="h1">STANDARD</Typography>
                      <Typography
                        sx={{
                          pt: 1,
                        }}
                        variant="subtitle2"
                        textAlign="center"
                      >
                        {t('275,000원 / 1개월 (VAT 포함)')}
                      </Typography>
                    </Box>
                    <List disablePadding>
                      {standardItems.map((item) => (
                        <Fragment key={item.id}>
                          <Divider />
                          <ListItemButtonWrapper
                            sx={{
                              display: { xs: 'block', sm: 'flex' },
                              py: 2,
                              px: 2.5,
                            }}
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography color="text.primary" variant="h5">
                                  {item.title}
                                </Typography>
                              }
                            />
                            <Box
                              component="span"
                              sx={{
                                display: { xs: 'none', sm: 'inline-block' },
                              }}
                            >
                              <Box textAlign="right">
                                {item.user ? (
                                  <Label
                                    style={{
                                      color: 'black',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {item.value}
                                  </Label>
                                ) : item.budget ? (
                                  <Label color="error">{item.value}</Label>
                                ) : (
                                  <Label color="success">{item.value}</Label>
                                )}
                              </Box>
                            </Box>
                          </ListItemButtonWrapper>
                        </Fragment>
                      ))}
                    </List>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                lg={3}
                xl={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexGrow={1}
                  sx={{
                    border: 1,
                    borderColor: '#5e90cc',
                    borderWidth: '1.5px',
                    borderRadius: '5px',
                    padding: '16px',
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        textAlign: 'center',
                        width: '200px',
                      }}
                    >
                      <Typography variant="h1" sx={{ color: '#5e90cc' }}>
                        PREMIUM
                      </Typography>
                      <Typography
                        sx={{
                          pt: 1,
                          color: '#5e90cc',
                        }}
                        variant="subtitle2"
                        textAlign="center"
                      >
                        {t('632,500원 / 1개월 (VAT 포함)')}
                      </Typography>
                    </Box>
                    <List disablePadding>
                      {premiumItems.map((item) => (
                        <Fragment key={item.id}>
                          <Divider />
                          <ListItemButtonWrapper
                            sx={{
                              display: { xs: 'block', sm: 'flex' },
                              mr: 0,
                              py: 2,
                              px: 2.5,
                            }}
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography color="text.primary" variant="h5">
                                  {item.title}
                                </Typography>
                              }
                            />
                            <Box
                              component="span"
                              sx={{
                                display: { xs: 'none', sm: 'inline-block' },
                              }}
                            >
                              <Box textAlign="right">
                                {item.user ? (
                                  <Label
                                    style={{
                                      color: 'black',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {item.value}
                                  </Label>
                                ) : item.budget ? (
                                  <Label color="error">{item.value}</Label>
                                ) : (
                                  <Label color="success">{item.value}</Label>
                                )}
                              </Box>
                            </Box>
                          </ListItemButtonWrapper>
                        </Fragment>
                      ))}
                    </List>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </ContentWrapper>
        </MainContent>
      </Content>
    </>
  );
};

export default Pricing;
