import { styled, alpha, Typography } from '@mui/material';
import Button from './elements/Button';
import ButtonGroup from './elements/ButtonGroup';

export const CoverP = styled(`p`)(
  ({ theme }) => `
  color: ${alpha(theme.palette.text.primary, 0.7)} !important;
  font-size: 1.2rem !important;
  @media (max-width: 599px) {
    font-size: 1rem !important;
  }
  word-Break: keep-all;
  white-space: normal;
  `,
);

export const HeroP = styled(`p`)(
  ({ theme }) => `
  color: ${alpha(theme.palette.text.primary, 0.7)} !important;
  font-size: 1.3rem !important;
  @media (max-width: 599px) {
    font-size: 0.9rem !important;
  }
  word-Break: keep-all;
  white-space: normal;
  `,
);

export const HeroH1 = styled(`h1`)(
  ({ theme }) => `
  color: ${theme.palette.text.primary} !important;
  font-size: 3.25rem !important;
  @media (max-width: 940px) {
    font-size: 2rem !important;
  }
  word-Break: keep-all;
  white-space: normal;    
  `,
);

export const HeroSpan = styled(`span`)(
  ({ theme }) => `
  color: ${theme.colors.primary.main} !important;
  word-Break: keep-all;
  white-space: normal;
  `,
);

export const HeroButton = styled(Button)(
  ({ theme }) => `
  color: #ffffff !important;
  background: ${theme.colors.primary.main} !important;
  margin-top: 16px !important;
  `,
);

export const HeroButtonGroup = styled(ButtonGroup)(
  () => `
  padding-top: 4rem;
  margin-bottom: -3.5rem !important;
  `,
);

export const HeroUniButton = styled(Button)(
  () => `
  color: #ffffff !important;
  background: #000000 !important;
  `,
);

export const SectionHeaderTitle = styled('h2')(
  ({ theme }) => `
  color: ${theme.palette.text.primary} !important;
  `,
);

export const FeaturesTilesH4 = styled('h4')(
  ({ theme }) => `
  color: ${theme.palette.text.primary} !important;
  `,
);

export const FeaturesTilesP = styled('p')(
  ({ theme }) => `
  color: ${alpha(theme.palette.text.primary, 0.7)} !important;
  `,
);

export const FeaturesTilesDiv = styled('div')(
  ({ theme }) => `
  display: inline-flex;
  border-radius: 50%;
  background: ${theme.colors.primary.main};
  `,
);

export const FooterP = styled('p')(
  ({ theme }) => `
  color: ${alpha(theme.palette.text.primary, 0.7)} !important;
  `,
);

export const FooterA = styled('a')(
  ({ theme }) => `
  color: ${theme.palette.text.primary} !important;
  &:hover {
    color: ${theme.colors.primary.main} !important;
  }
  `,
);

export const FooterSocialA = styled('a')(
  ({ theme }) => `
  fill: ${theme.colors.primary.main};
  &:hover {
      fill: ${theme.palette.text.primary} !important;
  }
  `,
);

export const CoverHeroH2 = styled(`h2`)(
  ({ theme }) => `
  color: ${theme.palette.text.primary} !important;
  font-size: 2.1rem !important;
  @media (max-width: 599px) {
    font-size: 1.3rem !important;
  }
  word-Break: keep-all;
  white-space: normal;
  `,
);

export const HeroH2 = styled(`h2`)(
  ({ theme }) => `
  color: ${theme.palette.text.primary} !important;
  `,
);

export const HeroH3 = styled(`h3`)(
  ({ theme }) => `
  color: ${theme.palette.text.primary} !important;
  font-size: 2rem !important;
  @media (max-width: 940px) {
    font-size: 1.25rem !important;
  }
  `,
);

export const HeroH5 = styled(`h5`)(
  ({ theme }) => `
  color: ${theme.palette.text.primary} !important;
  word-Break: keep-all;
  white-space: normal;
  `,
);

export const WordBreakTypography = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.text.primary} !important;
  word-Break: keep-all;
  white-space: normal;
  `,
);
