import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { HeroH1, HeroSpan, HeroP } from '../style';
import { observer } from 'mobx-react-lite';
import { useTheme, alpha, Button, useMediaQuery } from '@mui/material';
import PageTab from 'src/components/PageTab';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const FeaturesImagePage = observer(
  ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {
    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    const theme = useTheme();
    const mobile = useMediaQuery('(max-width: 430px)');

    return (
      <section
        {...props}
        className={outerClasses}
        style={{ background: alpha(theme.palette.primary.main, 0.175) }}
      >
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content reveal-from-bottom">
              <HeroH1 className="mt-0 mb-8 ">
                {/* <HeroSpan>Okomo</HeroSpan> platform features */}
                <HeroSpan>Okomo</HeroSpan> 플랫폼 특징
              </HeroH1>
              <img
                style={{
                  width: mobile ? '15rem' : '20rem',
                  height: mobile ? '15rem' : '20rem',
                  marginTop: mobile ? '0rem' : '5rem',
                }}
                src={'/static/images/info/data-collection.png'}
                alt="FeaturesIcon"
              />
              <PageTab
                useScroll={!!mobile}
                tabList={[
                  // 'Device managements',
                  // 'Data collection',
                  // 'Configuration management',
                  // 'Comman execution',
                  '설비 관리',
                  '데이터 수집',
                  '구성 관리',
                  '명령 실행',
                ]}
                renderingByIndex={(selectedTabIndex) => {
                  switch (selectedTabIndex) {
                    case 0:
                      return (
                        // <HeroP style={{ marginTop: '2.5rem' }}>
                        //   Manage devices and their credentials individually or
                        //   in groups. Maintain a register of digital twins so all
                        //   the device's data will be available even when it's
                        //   offline.
                        // </HeroP>
                        <HeroP style={{ marginTop: '2.5rem' }}>
                          설비의 인증 정보를 개별적으로 또는 그룹 단위로
                          관리합니다. 디지털 트윈 레지스터를 적용하여 오프라인
                          상태에서도 설비의 모든 데이터를 사용할 수 있도록
                          합니다.
                        </HeroP>
                      );

                    case 1:
                      return (
                        // <HeroP style={{ marginTop: '2.5rem' }}>
                        //   Reliably collect data on a large scale. Configure data
                        //   processing pipelines. Optimize network and battery use
                        //   with batching. Okomo Enegry Platform is
                        //   pre-integrated with production-ready databases like
                        //   BigQuery, and others.
                        // </HeroP>
                        <HeroP style={{ marginTop: '2.5rem' }}>
                          대규모 데이터를 안정적으로 수집하고, 효율적인 데이터
                          처리 구성 시스템을 제공하며, 배칭으로 네트워크 및
                          배터리 사용을 최적화합니다. Okomo 에너지 플랫폼은
                          BigQuery 등과 같은 프로덕션에 적합한 데이터베이스와
                          사전 통합되어 있습니다.
                        </HeroP>
                      );

                    case 2:
                      return (
                        // <HeroP style={{ marginTop: '2.5rem' }}>
                        //   Configuration management is essential for controlling
                        //   the device behavior, managing data processing
                        //   parameters, edge analytics, feature flagging, and
                        //   other functions. For example, you can configure an air
                        //   conditioner with the desired temperature, set
                        //   reporting period for a smart meter, etc.
                        // </HeroP>
                        <HeroP style={{ marginTop: '2.5rem' }}>
                          구성 관리는 설비 제어, 데이터 처리 매개변수 관리, 엣지
                          분석, 기능 활성화 지정과 같은 전반적인 기능을 위한
                          필수 요소입니다. 예를 들어, 냉동 창고를 원하는 온도로
                          설정하거나 게이트웨이의 통신 주기를 설정하는 등의
                          작업을 수행할 수 있습니다.
                        </HeroP>
                      );

                    case 3:
                      return (
                        // <HeroP style={{ marginTop: '2.5rem' }}>
                        //   Command execution allows you to deliver messages with
                        //   the arbitrary payload to connected devices, execute
                        //   commands, and receive near-real-time responses. For
                        //   example, you can remotely check the current
                        //   temperature on a home thermostat, open a vehicle
                        //   trunk, and so on.
                        // </HeroP>
                        <HeroP style={{ marginTop: '2.5rem' }}>
                          명령 실행 기능을 통해 페이로드를 포함한 메시지를
                          연결된 기기로 전송하고, 명령을 실행하며 거의
                          실시간으로 응답을 받을 수 있습니다. 예를 들어,
                          냉장고의 온도 조절기에서 현재 온도를 원격으로
                          확인하거나 전기차 충전기를 재부팅 할 수 있습니다.
                        </HeroP>
                      );

                    default:
                      return null;
                  }
                }}
              />
              <Button
                style={{ padding: '1rem', marginTop: '5rem', width: '150px' }}
                variant="contained"
                wideMobile
              >
                {/* Learn more */}더 알아보기
              </Button>
            </div>
          </div>
        </div>
      </section>
    );
  },
);

FeaturesImagePage.propTypes = propTypes;
FeaturesImagePage.defaultProps = defaultProps;

export default FeaturesImagePage;
