import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { HeroH1, HeroH3, HeroSpan, HeroP } from '../style';
import { observer } from 'mobx-react-lite';
import { Grid, useTheme, alpha, Button, useMediaQuery } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const ServicesPage = observer(
  ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {
    const theme = useTheme();
    const mobile = useMediaQuery('(max-width: 940px)');

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container-sm" style={{ marginTop: '-3rem' }}>
          <div className={innerClasses}>
            <div className="hero-content reveal-from-bottom">
              <HeroH3>
                <HeroSpan style={{ fontWeight: 'bold' }}>
                  {/* B2B ENEGRY SERVICES */}
                  B2B 에너지 서비스
                </HeroSpan>
              </HeroH3>
              {/* <HeroH1 className="mt-0 mb-8 ">Professional services</HeroH1> */}
              <HeroH1 className="mt-0 mb-8 ">전문적인 서비스</HeroH1>
              {/* <HeroP style={{ marginTop: '3.5rem', marginBottom: '3.5rem' }}>
                As a full-service enegry company, we offer enegry development
                and integration services based on our Okomo Enterprise Enegry
                Platform and popular industry-specific enegry technologies. In
                addition to enegry, we have a deep expertise in Big Data, Cloud,
                Embedded, Blockchain, Machine Learning, and other modern
                domains. Okomo Enterprise Enegry Platform was initially created
                as one of the CyberVision Inc projects and rapidly evolved to
                the point when we decided to found a separate company with a
                strict focus on the Okomo. Combining more than 25 years of
                experience from CyberVision and Okomo enegry we offer full-stack
                enegry implementation services to help you go to market fast.
              </HeroP> */}
              <HeroP style={{ marginTop: '3.5rem', marginBottom: '3.5rem' }}>
                저희는 종합 에너지 서비스 기업으로, Okomo 플랫폼과 업계 특화
                에너지 기술을 기반으로 에너지 개발 및 통합 서비스를 제공합니다.
                에너지 분야뿐만 아니라 빅데이터, 클라우드, 임베디드, 블록체인,
                머신러닝 등 현대적인 기술 영역에서도 깊은 전문성을 보유하고
                있습니다. Okomo 플랫폼은 원래 CyberVision Inc.의 프로젝트 중
                하나로 시작되었으며, 빠르게 발전해 독립적인 회사로 분리되어
                Okomo에 집중하게 되었습니다. CyberVision과 Okomo 플랫폼의 25년
                이상의 경험을 결합하여 시장에 빠르게 진출할 수 있도록 돕는
                풀스택 에너지 구현 서비스를 제공합니다.
              </HeroP>
              {/* <HeroSpan>
                Feel free to contact us with your specific problem or need and
                we'll be glad to help you out.
              </HeroSpan> */}
              <HeroSpan>
                귀사의 특별한 문제나 요구 사항이 있으시면 언제든지 저희에게
                연락주세요. 기꺼이 도움을 드리겠습니다.
              </HeroSpan>
              <ul style={{ marginTop: '2.5rem' }}>
                <li
                  style={{
                    display: 'flex',
                    color: theme.palette.text.primary,
                    background: alpha(theme.palette.primary.main, 0.13),
                    alignItems: 'center',
                    padding: '12px',
                    margin: mobile ? '0 0 16px -24px' : '0 0 16px -16px',
                    fontSize: mobile ? '1rem' : '1.5rem',
                    borderRadius: '4px',
                  }}
                >
                  <div>
                    <CheckCircleIcon
                      style={{
                        color: theme.colors.primary.main,
                        background: alpha(theme.palette.primary.main, 0.5),
                        borderRadius: '0.8rem',
                        borderWidth: '1rem',
                        marginRight: '1rem',
                      }}
                    />
                  </div>
                  {/* <span style={{ fontWeight: 'bold' }}>
                    Business case assessment
                  </span> */}
                  <span style={{ fontWeight: 'bold' }}>비즈니스 유형 분석</span>
                </li>
                <li
                  style={{
                    display: 'flex',
                    color: theme.palette.text.primary,
                    background: alpha(theme.palette.primary.main, 0.13),
                    alignItems: 'center',
                    padding: '12px',
                    margin: mobile ? '0 0 16px -24px' : '0 0 16px -16px',
                    fontSize: mobile ? '1rem' : '1.5rem',
                    borderRadius: '4px',
                  }}
                >
                  <div>
                    <CheckCircleIcon
                      style={{
                        color: theme.colors.primary.main,
                        background: alpha(theme.palette.primary.main, 0.5),
                        borderRadius: '0.8rem',
                        borderWidth: '1rem',
                        marginRight: '1rem',
                      }}
                    />
                  </div>
                  {/* <span style={{ fontWeight: 'bold' }}>
                    Building a proof of concept
                  </span> */}
                  <span style={{ fontWeight: 'bold' }}>
                    요구사항 실현을 위한 시범 구축
                  </span>
                </li>
                <li
                  style={{
                    display: 'flex',
                    color: theme.palette.text.primary,
                    background: alpha(theme.palette.primary.main, 0.13),
                    alignItems: 'center',
                    padding: '12px',
                    margin: mobile ? '0 0 16px -24px' : '0 0 16px -16px',
                    fontSize: mobile ? '1rem' : '1.5rem',
                    borderRadius: '4px',
                  }}
                >
                  <div>
                    <CheckCircleIcon
                      style={{
                        color: theme.colors.primary.main,
                        background: alpha(theme.palette.primary.main, 0.5),
                        borderRadius: '0.8rem',
                        borderWidth: '1rem',
                        marginRight: '1rem',
                      }}
                    />
                  </div>
                  {/* <span style={{ fontWeight: 'bold' }}>
                    Fulfilling the project milestones
                  </span> */}
                  <span style={{ fontWeight: 'bold' }}>
                    프로젝트 진행과 목표 달성
                  </span>
                </li>
                <li
                  style={{
                    display: 'flex',
                    color: theme.palette.text.primary,
                    background: alpha(theme.palette.primary.main, 0.13),
                    alignItems: 'center',
                    padding: '12px',
                    margin: mobile ? '0 0 16px -24px' : '0 0 16px -16px',
                    fontSize: mobile ? '1rem' : '1.5rem',
                    borderRadius: '4px',
                  }}
                >
                  <div>
                    <CheckCircleIcon
                      style={{
                        color: theme.colors.primary.main,
                        background: alpha(theme.palette.primary.main, 0.5),
                        borderRadius: '0.8rem',
                        borderWidth: '1rem',
                        marginRight: '1rem',
                      }}
                    />
                  </div>
                  {/* <span style={{ fontWeight: 'bold' }}>
                    Rolling out the final solution
                  </span> */}
                  <span style={{ fontWeight: 'bold' }}>최종 솔루션 도입</span>
                </li>
                <li
                  style={{
                    display: 'flex',
                    color: theme.palette.text.primary,
                    background: alpha(theme.palette.primary.main, 0.13),
                    alignItems: 'center',
                    padding: '12px',
                    margin: mobile ? '0 0 16px -24px' : '0 0 16px -16px',
                    fontSize: mobile ? '1rem' : '1.5rem',
                    borderRadius: '4px',
                  }}
                >
                  <div>
                    <CheckCircleIcon
                      style={{
                        color: theme.colors.primary.main,
                        background: alpha(theme.palette.primary.main, 0.5),
                        borderRadius: '0.8rem',
                        borderWidth: '1rem',
                        marginRight: '1rem',
                      }}
                    />
                  </div>
                  {/* <span style={{ fontWeight: 'bold' }}>
                    Post-production support
                  </span> */}
                  <span style={{ fontWeight: 'bold' }}>사후 지원 서비스</span>
                </li>
              </ul>
            </div>
          </div>
          <Grid
            container
            style={{
              width: mobile ? '100%' : '102%',
              background: alpha(theme.palette.primary.main, 0.13),
            }}
          >
            {mobile ? (
              <>
                <Grid item xs={12}>
                  <img
                    style={{
                      width: '35rem',
                      height: '14rem',
                    }}
                    src={'/static/images/info/laptop.png'}
                    alt="FeaturesIcon"
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeroH3
                    style={{
                      marginTop: '2rem',
                    }}
                    className="mt-8 mb-8"
                  >
                    {/* Ready to get started? */}
                    시작할 준비가 됐나요?
                  </HeroH3>
                  <HeroP
                    style={{
                      marginTop: '2rem',
                      marginBottom: '3.5rem',
                    }}
                  >
                    {/* Create a free Okomo Cloud account or apply for a free trial
                    with the professional assistance. */}
                    체험판 Okomo 계정을 생성하거나 전문가의 도움을 받아 무료
                    체험을 신청하세요.
                  </HeroP>
                  <Button
                    style={{ padding: '1rem', marginBottom: '2rem' }}
                    variant="contained"
                    wideMobile
                  >
                    {/* Use Okomo for free */}
                    Okomo 무료로 이용하기
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <img
                    style={{
                      margin: '-2rem 0rem 0rem -2rem',
                      position: 'absolute',
                      width: '37.5rem',
                      height: '20rem',
                    }}
                    src={'/static/images/info/laptop.png'}
                    alt="FeaturesIcon"
                  />
                </Grid>
                <Grid item xs={6}>
                  <HeroH3
                    style={{
                      marginLeft: '2rem',
                    }}
                    className="mt-8 mb-8"
                  >
                    {/* Ready to get started? */}
                    시작할 준비가 됐나요?
                  </HeroH3>
                  <HeroP
                    style={{
                      marginTop: '3.5rem',
                      marginBottom: '3.5rem',
                      marginLeft: '2rem',
                      padding: '0.7rem',
                    }}
                  >
                    {/* Create a free Okomo account or apply for a free trial with
                    the professional assistance. */}
                    체험판 Okomo 계정을 생성하거나 전문가의 도움을 받아 무료
                    체험을 신청하세요.
                  </HeroP>
                  <Button
                    style={{ padding: '1rem', marginBottom: '2rem' }}
                    variant="contained"
                    wideMobile
                  >
                    {/* Use Okomo for free */}
                    Okomo 무료로 이용하기
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </section>
    );
  },
);

ServicesPage.propTypes = propTypes;
ServicesPage.defaultProps = defaultProps;

export default ServicesPage;
