import { Chip } from '@mui/material';
import React from 'react';
import { ChipDiv } from '../style';

const VersionChip = ({ sidebarOpen = true }) => {
  return (
    <ChipDiv sidebarOpen={sidebarOpen}>
      <Chip label={`V${process.env.REACT_APP_VERSION}`} />
    </ChipDiv>
  );
};

export default VersionChip;
